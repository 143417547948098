import React from "react"
import {
  ListHeader,
  InformationListItemHeading,
  InformationListItemBody,
  InformationListItemImage,
  InformationListItemImageContainer,
  InformationListColumn,
  InformationListRow,
  InformationListItemVerticalSeperator
} from "./ImagelInformationList.styles"
import { Grid } from "semantic-ui-react"
import { Alignments, SemanticColumns } from "@utils/KontentTypes"
import KpButton from "@elements/KpButton/KpButton"
import theme from "@styles/theme"


interface ImageInfoListProps {
  listTitle: string
  listItems: Array<{
    iconUrl: string
    title: string
    body: string
    linkText?: string
    linkUrl?: string
  }>
  backgroundColor: string | string[],
  columns: SemanticColumns
  horizontalLayout: boolean
  titleColor: string | string[]
  textColor: string | string[]
  textAlign: Alignments
  titleOverlayImage: boolean
  showAll?: boolean
  dividers?: 'vertical' | 'horizontal'
}

const ImageInformationList = ({ listTitle, listItems, backgroundColor, dividers, columns, showAll, horizontalLayout, titleColor, textColor, textAlign, titleOverlayImage }: ImageInfoListProps) => {
  return (
    <Grid stackable doubling columns={columns}>
      {
        listTitle &&
        <Grid.Row centered>
          <Grid.Column textAlign='center'>

            <ListHeader>{listTitle}</ListHeader>
          </Grid.Column>
        </Grid.Row>
      }
      <InformationListRow>
        {
          listItems.map((listItem, i) => {
            return <Grid.Column key={i} only={showAll || listItems.length === 3 ? '' : i >= 2 ? 'computer' : 'tablet computer mobile'}>
              <Grid padded stackable textAlign={textAlign} columns={horizontalLayout ? 2 : 1}>
                <InformationListRow>
                  <InformationListColumn dividers={dividers}>
                    <InformationListItemImageContainer titleOverlayImage={titleOverlayImage}>
                      <div>
                        {
                          textAlign === 'center' ?
                            <InformationListItemImage src={listItem.iconUrl} alt={listItem.title} centered />
                            :
                            <InformationListItemImage src={listItem.iconUrl} alt={listItem.title} />
                        }
                      </div>
                      {titleOverlayImage &&
                        <InformationListItemHeading informationListTitleColor={titleColor}>
                          {listItem.title}
                        </InformationListItemHeading>
                      }
                    </InformationListItemImageContainer>
                  </InformationListColumn>
                  <InformationListColumn dividers={dividers}>
                    {!titleOverlayImage &&
                      <InformationListItemHeading informationListTitleColor={titleColor}>
                        {listItem.title}
                      </InformationListItemHeading>
                    }
                    <InformationListItemBody informationListTextColor={textColor}>
                      {listItem.body}
                    </InformationListItemBody>
                    {
                      listItem.linkText && <KpButton link={listItem.linkUrl} id='' buttonType='alternate' color={textColor}>{listItem.linkText}</KpButton>
                    }
                  </InformationListColumn>
                  {
                    dividers === 'vertical' ? <InformationListItemVerticalSeperator></InformationListItemVerticalSeperator> : ''
                  }
                </InformationListRow>
              </Grid>
            </Grid.Column>
          })
        }
      </InformationListRow>
    </Grid>
  )
}

ImageInformationList.defaultProps = {
  listTitle: "",
  textAlign: "left"
}
export default ImageInformationList