import {convertKontentColorToHex} from '@utils/Helpers'
import { KenticoChoice, KenticoImage, Alignments } from '@utils/KontentTypes';

interface BannerContainerWithTitleProps{
    system:{
        name: string
    }
    elements:{
        background_colors:{
            value: KenticoChoice[]
        }
        text_alignment:{
            value: KenticoChoice[]
        }
        items: {
            linked_items: {
                elements:BannerItemWithLink
            }[]
        }
    }
}


export const mapToInfoBannerContainerWithTitle = (data:BannerContainerWithTitleProps | undefined) =>{
    if(data == undefined)
        return undefined;
    const {system, elements} = data;
    const {background_colors,text_alignment,items} = elements;
    const bgColor = convertKontentColorToHex(background_colors.value[0].codename);
    const alignment = text_alignment.value[0].codename;
    const itemData = items.linked_items.map(item=>mapToInfoBannerItemWithLink(item.elements))
    return{
        listTitle: system.name,
        backgroundColor: bgColor,
        textAlign: alignment,
        listItems: itemData
    }
}

interface BannerContainerProps{
    background_colors:{
        value: KenticoChoice[]
    }
    text_colors: {
        value: KenticoChoice[]
    }
    text_alignment:{
        value: KenticoChoice[]
    }
    items:{
        linked_items:{
            elements: BannerItem
        }[]
    }

}
export const mapToInfoBannerContainer = (data:BannerContainerProps) =>{
    
    if(data == undefined)
        return undefined;
    const {background_colors,text_colors,text_alignment,items} = data;
    const backgroundColor = convertKontentColorToHex(background_colors.value[0].codename);
    const textColor = convertKontentColorToHex(text_colors.value[0].codename);
    const alignment = text_alignment.value[0].codename as Alignments;
    const itemData = items.linked_items.map(item=>mapToInfoBannerItem(item.elements))
    return{
        backgroundColor: backgroundColor,
        textColor: textColor,
        textAlign: alignment,
        listItems: itemData
    }
}

interface BannerItem{
    icon:{
        value: KenticoImage[]
    }
    title:{
        value: string
    }
    description:{
        value: string
    }
    linktext: {
        value: string
    }
    linkurl: {
        value: string
    }
}

const mapToInfoBannerItem = (data:BannerItem) =>{
    const {icon, title, description, linkurl, linktext} = data;
    return {
        iconUrl: icon.value[0].url,
        title: title.value,
        body: description.value,
        linkText: !linktext ? '' : linktext.value,
        linkUrl: !linkurl ? '' : linkurl.value,
    }
}

interface BannerItemWithLink{
    icon:{
        value: KenticoImage[]
    }
    title:{
        value: string
    }
    description:{
        value: string
    }
    linktext:{
        value: string
    }
    linkurl:{
        value: string
    }
}

const mapToInfoBannerItemWithLink = (data:BannerItemWithLink) =>{
    const {icon, title, description, linktext,linkurl} = data;
    return {
        iconUrl: icon.value[0].url,
        title: title.value,
        body: description.value,
        linkText: linktext.value,
        linkUrl: linkurl.value
    }
}