import React from 'react'
import styled from '@emotion/styled'
import {Segment, Image, GridColumn, GridRow} from 'semantic-ui-react'
import { H2, H4, Paragraph1 } from '@styles/Global.styles'
import theme from '@styles/theme';
import mq from '@styles/mq';

export const InformationListSegment = styled(({backgroundColor, ...rest})=> <Segment {...rest} />)`
  &&& {
    border: none;
    border-radius: 0;
    margin: 0;
    background-color: ${props => props.backgroundColor}; 
    box-shadow: none;
  }
`;

export const ImageInformationListTitle = styled(H2)`
  margin-bottom: 30px;
`

export const InformationListItemContainer = styled.div`
  margin-top: 30px;
  position: relative;
`
export const ListHeader = styled(H2)`
`
export const InformationListItemImageContainer = styled(({titleOverlayImage, ...rest})=><div {...rest}/>)`
  width: 100%;
  position: relative;
  margin-bottom: 10px;

  ${props => {
    if(props.titleOverlayImage){
      return `     
      margin-bottom: 15px;  

      div{
        border-radius: 5px;
        position: relative;

        :after {
          content: '';
          position: absolute;
          width: 100%; 
          height:100%;
          top:0; 
          z-index: 1;
          left:0;
          background:rgba(0,0,0,0.3);
          opacity: 1;
        }
      }

      h4 {
        z-index: 1;
        display: block;
        margin: 0;
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
      }
      `
    }
  }}
`

export const InformationListItemImage = styled(Image)`
  max-width: 100%;
  max-height: 100%;
  border-radius: 5px;
  margin-bottom: 15px;
`

export const InformationListItemHeading  = styled(({informationListTitleColor, ...rest})=><H4 {...rest} />)`
  color: ${props => props.informationListTitleColor}; 
  margin: 10px 0 5px 0;
  font-weight: bold;
  border-radius: 5px;
`

export const InformationListItemBody = styled(({informationListTextColor, ...rest})=><Paragraph1 {...rest} />)`
  color: ${props => props.informationListTextColor}; 
  font-size: 16px !important;
  font-weight: 300;
  margin-bottom: 15px;
`

export const InformationListColumn = styled(({dividers, ...rest})=><GridColumn {...rest} />)`
&&&& {
  &.column {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    &.parent {
      ${mq({
        paddingLeft: ['10px !important','0 !important','0 !important'],
        paddingRight: ['10px !important','0 !important','0 !important'],
        width: ['auto', '33.33% !important', 'auto'],
        marginBottom: ['60px !important;','0','0']
      })}
    }
  }  
}  

  :not(:first-of-type) {
    ${props => props.dividers === 'horizontal' ? `border-right: 2px solid ${theme.brand.colors.lightGrey};` : ''}; 
    ${props => props.dividers === 'vertical' ? 'padding-bottom: 30px;' : 'horizontal' ? 'padding-right: 30px;' : ''}; 
  }
`

export const InformationListRow = styled(({dividers, ...rest})=><GridRow {...rest} />)`
&&&& {
  &.row {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }  
}  

`
export const InformationListItemVerticalSeperator = styled.div`
  height: 3px;
  width: 100%;
  background-color: ${theme.brand.colors.darkGrey};
  margin: 30px 1rem 15px 1rem;
`
